import React from 'react'
import i18n from './index.i18n'
import { Box } from '@material-ui/core'
import { useI18n } from '@/app/ui/components/hooks/I18n'

import {
    StyledAlertBanner,
    StyledAlertBannerContent,
    StyledButton,
    StyledTypography,
} from './index.styled'
import { UuidV4 } from '@lazr/types'

export const CreditApplicationRequestAlertBanner: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)
    const hideUntil = +(localStorage.getItem('hideCreditApplicationRequestAlertUntil') || 0)
    const [ isVisible, setIsVisible ] = React.useState<boolean>(!hideUntil || Date.now() > hideUntil)

    if (!isVisible) {
        return null
    }

    return <Box
        display='flex'
        mb={8.5}
        justifyContent='center'
    >
        <StyledAlertBanner
            severity='info'
            width={'1224px'}
            onClose={() => {
                localStorage.setItem('hideCreditApplicationRequestAlertUntil', (Date.now() + /*86400*/30 * 1000).toString())
                setIsVisible(false)
            }}
        >
            <StyledAlertBannerContent
                title={t('Credit Application Request')}
                message={
                    <StyledTypography variant='body1'>
                        {
                            // eslint-disable-next-line max-len
                            props.isSystemOrOrganizationAdmin ?
                                t('Lazr would like to learn more about your business to assess the credit you need. Please complete the credit application details.')
                                : t('Lazr would like to learn more about your business to assess the credit you need. Please ask your organization administrator to fill in the details.')
                        }
                    </StyledTypography>
                }
                buttons={
                   props.isSystemOrOrganizationAdmin ?
                       <StyledButton href={`../new/credit-application/new/${props.organizationId || ''}`} hidden={!props.isSystemOrOrganizationAdmin}>
                        {t('Go to Credit Application')}
                    </StyledButton>
                    : undefined
                }
            />
        </StyledAlertBanner>
    </Box>
}

export interface Props {
    isSystemOrOrganizationAdmin: boolean
    organizationId: UuidV4
}
