/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import { StyledStrong } from './index.styled'

export default {
    [`Credit Application Request`]: `Credit Application Request`,
    [`Lazr would like to learn more about your business to assess the credit you need. Please complete the credit application details.`]: `Lazr would like to learn more about your business to assess the credit you need. Please complete the credit application details.`,
    [`Lazr would like to learn more about your business to assess the credit you need. Please ask your organization administrator to fill in the details.`]: `Lazr would like to learn more about your business to assess the credit you need. Please ask your organization administrator to fill in the details.`,

    [`Go to Credit Application`]: `Go to Credit Application`,
}
