/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'
import { StyledStrong } from './index.styled'

export default ((): Translations<typeof en> => ({
    [`Credit Application Request`]: `Demande de demande de crédit`,
    [`Lazr would like to learn more about your business to assess the credit you need. Please complete the credit application details.`]: `Lazr souhaite en savoir plus sur votre entreprise afin d'évaluer le crédit dont vous avez besoin. Veuillez remplir les détails de la demande de crédit.`,
    [`Lazr would like to learn more about your business to assess the credit you need. Please ask your organization administrator to fill in the details.`]: `Lazr souhaite en savoir plus sur votre entreprise afin d'évaluer le crédit dont vous avez besoin. Veuillez demander à l'administrateur de votre organisation de remplir les détails.`,
    [`Go to Credit Application`]: `Accédez à la demande de crédit`,
}))()
